import request from "@/utils/request";

//创建数据
export async function create(data) {
  return request({
    url: "/happyroom/wenjuanResult/",
    method: "post",
    data,
  });
}

//获取数据详情
export function getDetail(id) {
  return request({
    url: `/happyroom/wenjuanResult/${id}`,
    method: "get",
  });
}
