import request from "@/utils/request";

//获取数据列表
export function getList(params) {
  return request({
    url: "/happyroom/wenjuan/",
    method: "get",
    params,
  });
}
//获取数据列表
export function getDetail(id) {
  return request({
    url: `/happyroom/wenjuan/${id}`,
    method: "get",
  });
}
